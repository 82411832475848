/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// @use '@angular/material' as mat;
//@import "app/_shared/globals.scss";

// @import "@angular/material/prebuilt-themes/purple-green.css";
// //@import "@angular/material/prebuilt-themes/indigo-pink.css";
// //@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// //@import "@angular/material/prebuilt-themes/pink-bluegrey.css";

$mainColor: #84ff30;
$secondaryColor: darkslategray;
$backgroundColor: #74a57f;
$thirdColor: #537171;
$forthColor: #74a57f;
$fifthColor: #9ece9a;

$firstColumnWidth: 300px;

$green-primary: (
    50 : #E0F2F1,
    100 : #B2DFDB,
    200 : #80CBC4,
    300 : #4DB6AC,
    400 : #26A69A,
    500 : #84FF30,
    600 : #00897B,
    700 : #00796B,
    800 : #00695C,
    900 : #004D40,
    A100 : #84FF30,
    A200 : #2E4F4F,
    A400 : #436161,
    A700 : #00BFA5,
    contrast: (
        50 :  #ffffff,
        100 :  #ffffff,
        200 :  #ffffff,
        300 :  #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 :  #ffffff,
        A200 :  #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$green-accent: (
  0 : #E0F2F1,
  100 : #B2DFDB,
  200 : #80CBC4,
  300 : #4DB6AC,
  400 : #26A69A,
  500 : #009688,
  600 : #00897B,
  700 : #00796B,
  800 : #00695C,
  900 : #004D40,
  A100 : #84FF30,
  A200 : #2E4F4F,
  A400 : #436161,
  A700 : #00BFA5,
  contrast: (
      50 :  #ffffff,
      100 :  #ffffff,
      200 :  #ffffff,
      300 :  #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 :  #ffffff,
      A200 :  #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$green-app-primary: mat.define-palette($green-primary);
$green-app-accent:  mat.define-palette($green-accent);
// The "warn" palette is optional and defaults to red if not specified.
$green-app-warn: mat.define-palette(mat.$red-palette);

// The warn palette is optional (defaults to red).
//$candy-app-warn:    mat.define-palette($forthColor);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$green-app-theme: mat.define-dark-theme((
  color: (
    primary: $green-app-primary,
    accent: $green-app-accent,
    warn: $green-app-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
 ));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($green-app-theme);

html,
body {
  height: 100%;
  background-color: $backgroundColor;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//***** Sélection des personnages *****/
$characterSelectionWidth: 250px;

.char-selection {
  margin-left: $firstColumnWidth;
  margin-top: 15px;
  display: flex;
}

.char-selection-char {
  min-width: $characterSelectionWidth;
  max-width: $characterSelectionWidth;
}

//***** Tableau de comparaison *****/
.comparator-table-column {
  min-width: $characterSelectionWidth;
  max-width: $characterSelectionWidth;
  text-align: center;  

  label{
    font-size: medium;
  }
}
.comparator-table{
  background-color: $secondaryColor;
}

td,
th {
  padding: 0px !important;
  background-color: $secondaryColor;
}

th.mat-header-cell {
  text-align: center;
  font-size: medium;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: grey;
}

@media (max-width: 1350px) {
  .char-4-display{
    display: none;
  }
  }
  
  @media (max-width: 1100px) {
    .char-3-display{
      display: none;
    }
  }
  
  @media (max-width: 769px) {
    .char-selection {
      margin-left: 100px;
      margin-top: 15px;
      display: flex;
    }
  
    .firstColumn{
      max-width: 100px;
    }
  }
  